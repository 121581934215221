import { ACTION_TYPES } from "./types";

export const userLoginSuccess = (payload) => ({
  type: ACTION_TYPES.USER_LOGIN_SUCCESS,
  payload,
});

export const userLoginFail = (payload) => ({
  type: ACTION_TYPES.USER_LOGIN_FAIL,
  payload,
});

export const userLogout = () => ({
  type: ACTION_TYPES.USER_LOGOUT,
});

export const clearCheckSync = () => ({
  type: ACTION_TYPES.CHECK_SYNC_CLEAR,
});

export const clearApiDetailsState = () => ({
  type: ACTION_TYPES.CLEAR_API_DETAILS_STATE,
});

export const clearEnableDisableUserStoreInfo = () => ({
  type: ACTION_TYPES.ENABLE_DISABLE_USER_STORE_INFO_CLEAR,
});

export const clearAutoSyncStatus = () => ({
  type: ACTION_TYPES.IS_AUTO_SYNCING_CLEAR,
});

export const clearCcbAccountSetup = () => ({
  type: ACTION_TYPES.AUTHORIZE_CCB_ACCOUNT_CLEAR,
});

export const clearMcAccountSetup = () => ({
  type: ACTION_TYPES.AUTHORIZE_MC_ACCOUNT_CLEAR,
});

export const clearIntialSync = () => ({
  type: ACTION_TYPES.CLEAR_INTIAL_SYNCING,
});

export const clearEnableDisableSyncing = () => ({
  type: ACTION_TYPES.ENABLE_DISABLE_CCB_SYNCING_CLEAR,
});

export const clearIntercomAppIdStatus = () => ({
  type: ACTION_TYPES.GET_INTERCOM_APP_ID_CLEAR,
});
